import { Toast } from "vant";
let u = navigator.userAgent,
  app = navigator.appVersion,
  data = "dataNull";
let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
const util = {
  compareVersions(version1, version2) {  
    // 将版本号字符串拆分为数组  
    const arr1 = version1.split('.');  
    const arr2 = version2.split('.');  
    
    // 获取两个版本号数组的最大长度  
    const maxLength = Math.max(arr1.length, arr2.length);  
    
    // 逐位比较版本号  
    for (let i = 0; i < maxLength; i++) {  
      // 获取当前位置的数字，如果数组越界则默认为0  
      const num1 = i < arr1.length ? parseInt(arr1[i], 10) : 0;  
      const num2 = i < arr2.length ? parseInt(arr2[i], 10) : 0;  
    
      // 比较数字大小  
      if (num1 < num2) {  
        // 第一个版本号小于第二个版本号  
        return -1;  
      } else if (num1 > num2) {  
        // 第一个版本号大于第二个版本号  
        return 1;  
      }  
    }  
    
    // 如果所有位数都相等，则版本号相同  
    return 0;  
  },
  
  // 设置图片上传张数-安卓
  setPicCount(str) {
    if (util.getUserSystem() === "android") {
      try {
        window.EntrepreneurialWorld.setPicCount(str);
      } catch (e) {
        console.log(e);
      }
    }
  },
  // 预览pdf
  previewPdf(str) {
    if (util.getUserSystem() === "android") {
      try {
        window.EntrepreneurialWorld.previewPdf(str);
      } catch (e) {
        console.log(e);
      }
    } else if (util.getUserSystem() === "ios") {
      try {
        console.log("getUserSystem:---ios ", str);
        window.webkit.messageHandlers.previewPdf.postMessage(str);
      } catch (e) {
        console.log(e);
      }
    }
  },
  // 获取url参数
  getUrlParam(name) {
    const urlObject = {};
    const url = window.location.href;
    if (/\?/.test(url)) {
      var urlString = url.substring(url.indexOf("?") + 1);
      var urlArray = urlString.split("&");
      for (var i = 0, len = urlArray.length; i < len; i++) {
        var urlItem = urlArray[i];
        var item = urlItem.split("=");
        urlObject[item[0]] = item[1];
      }
      return urlObject[name];
    }
  },
  // 判断用户手机系统
  getUserSystem() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      return "ios";
    } else if (/(Android)/i.test(navigator.userAgent)) {
      return "android";
    }
  },
  // 在客户端获取互通信息
  getAppData() {
    let data = {};
    try {
      if (util.getUserSystem() === "ios") {
        if (cytxUserInfo && typeof cytxUserInfo == "object") {
          data = cytxUserInfo;
          sessionStorage.setItem("access_token", data.access_token);
          return data;
        }
      } else if (util.getUserSystem() === "android") {
        if (EntrepreneurialWorld.cytxUserInfo()) {
          data = JSON.parse(EntrepreneurialWorld.cytxUserInfo());
          sessionStorage.setItem("access_token", data.access_token);
          return data;
        }
      } else {
        if (location.href.indexOf("access_token") > 0) {
          const TOKEN = location.search.split("access_token=")[1].split("&")[0];
          data["access_token"] = TOKEN;
          sessionStorage.setItem("access_token", TOKEN);
        }
        return data;
      }
    } catch (error) {
      if (location.href.indexOf("access_token") > 0) {
        const TOKEN = location.search.split("access_token=")[1].split("&")[0];
        data["access_token"] = TOKEN;
        sessionStorage.setItem("access_token", TOKEN);
      }
      return data;
    }
  },
  // 拨打电话
  makingCall(phone) {
    if (isIOS) {
      try {
        window.webkit.messageHandlers.makingCall.postMessage(phone);
      } catch (e) {
        console.log("暂无拨打电话方法");
      }
    } else if (isAndroid) {
      try {
        EntrepreneurialWorld.makingCall(phone);
      } catch (e) {
        console.log("暂无拨打电话方法");
      }
    }
  },
  // 返回上一页
  toBack() {
    if (isIOS) {
      try {
        window.webkit.messageHandlers.toBack.postMessage("1");
      } catch (e) {
        console.log("暂无返回方法");
      }
    } else if (isAndroid) {
      try {
        EntrepreneurialWorld.toBack();
      } catch (e) {
        console.log("暂无返回方法");
      }
    }
  },
  // 跳转登录页
  toLogin() {
    if (isIOS) {
      try {
        window.webkit.messageHandlers.toLogin.postMessage("1");
      } catch (e) {
        console.log("暂无跳转登录方法");
      }
    } else if (isAndroid) {
      try {
        EntrepreneurialWorld.toLogin();
      } catch (e) {
        console.log("暂无跳转登录方法");
      }
    }
  },
  // 跳转奖品中心
  toPrize() {
    if (isIOS) {
      try {
        window.webkit.messageHandlers.toPrize.postMessage(this.phoneNumber);
      } catch (e) {
        console.log("暂无跳转奖品中心方法");
      }
    } else if (isAndroid) {
      try {
        EntrepreneurialWorld.toPrize();
      } catch (e) {
        console.log("暂无跳转奖品中心方法");
      }
    }
  },
  // 检测是否实名认证
  needAuthentication() {
    if (isIOS) {
      try {
        window.webkit.messageHandlers.needAuthentication.postMessage(
          this.phoneNumber
        );
      } catch (e) {
        console.log("暂无检测是否实名认证方法");
      }
    } else if (isAndroid) {
      try {
        EntrepreneurialWorld.needAuthentication();
      } catch (e) {
        console.log("暂无检测是否实名认证方法");
      }
    }
  },
  // 跳实名认证
  toAuthentication() {
    console.log("跳转实名认证");
    if (isIOS) {
      try {
        window.webkit.messageHandlers.toAuthentication.postMessage(
          this.phoneNumber
        );
      } catch (e) {
        console.log("暂无跳转实名认证方法");
      }
    } else if (isAndroid) {
      try {
        EntrepreneurialWorld.toAuthentication();
      } catch (e) {
        console.log("暂无跳转实名认证方法");
      }
    }
  },
  //完善资料
  toImproveTheInformation() {
    if (isIOS) {
      try {
        window.webkit.messageHandlers.improveTheInformation.postMessage("1");
      } catch (e) {
        console.log("暂无跳转完善资料方法");
      }
    } else if (isAndroid) {
      try {
        EntrepreneurialWorld.improveTheInformation();
      } catch (e) {
        console.log("暂无跳转完善资料方法");
      }
    }
  },
  // 隐藏title
  hideTitle() {
    if (isAndroid) {
      try {
        EntrepreneurialWorld.hideTitle();
      } catch (e) {
        //TODO handle the exception
        console.log(e, "android-隐藏导航栏title失败");
      }
    }
  },
  // 设置title
  getTitle(title) {
    if (isAndroid) {
      try {
        EntrepreneurialWorld.getTitle(title);
      } catch (e) {
        //TODO handle the exception
        console.log(e, "android-设置导航栏title失败");
      }
    } else if (isIOS) {
      try {
        window.webkit.messageHandlers.setCurrentTitle.postMessage(title);
      } catch (e) {
        //TODO handle the exception
        console.log(e, "ios-设置导航栏title失败");
      }
    }
  },

  // 通知app刷新补贴
  prizeExchangeCompleted() {
    try {
      if (isIOS) {
        window.webkit.messageHandlers.prizeExchangeCompleted.postMessage("1");
      } else {
        EntrepreneurialWorld.prizeExchangeCompleted();
      }
    } catch (error) {
      console.log(error);
    }
  },
  // 供应商设置app导航栏title
  setNavigatorTitle(title) {
    try {
      if (isIOS) {
        window.webkit.messageHandlers.setCurrentTitle.postMessage(title);
      } else {
        EntrepreneurialWorld.setCurrentTitle(title);
      }
    } catch (error) {
      console.log(error);
    }
  },

  // 供应商设置app导航栏颜色
  setBackgroundColor(data) {
    try {
      if (isIOS) {
        window.webkit.messageHandlers.setBackgroundColor.postMessage(data);
      } else {
        EntrepreneurialWorld.setBackgroundColor(data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  // 减法操作
  mathSub(arg1, arg2) {
    var r1, r2, m, n;
    arg1 === "0.00" && (arg1 = 0);
    arg2 === "0.00" && (arg2 = 0);
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (error) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (error) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
  },
  // 加法操作
  mathAdd(arg1, arg2) {
    var r1, r2, m, c;
    arg1 === "0.00" && (arg1 = 0);
    arg2 === "0.00" && (arg2 = 0);
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (error) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (error) {
      r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
      var cm = Math.pow(10, c);
      if (r1 > r2) {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", "")) * cm;
      } else {
        arg1 = Number(arg1.toString().replace(".", "")) * cm;
        arg2 = Number(arg2.toString().replace(".", ""));
      }
    } else {
      arg1 = Number(arg1.toString().replace(".", ""));
      arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m;
  },
  //数字补全
  mathComplement(num) {
    if (!num) return
    let Num = num.toString().split(".");
    let price = "";
    if (Num[1]) {
      if (Num[1].length > 2) {
        //小数点后位数大于2
        const dot = Num[1].slice(0, 2);
        price = `${Num[0]}.${dot}`;
      } else if (Num[1].length == 1) {
        //小数点后位数等于1
        price = `${num}0`;
      } else {
        //小数点后位数等于2
        price = num;
      }
    } else {
      price = `${num}.00`;
    }
    return price;
  },
  mathMultiply(arg1, arg2) {
    var m = 0;
    var s1 = arg1.toString();
    var s2 = arg2.toString();
    try {
      if (s1.indexOf(".") > 0) {
        m += s1.split(".")[1].length;
      }
      if (s2.indexOf(".") > 0) {
        m += s2.split(".")[1].length;
      }
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    } catch (error) {
      console.log(error);
    }
  },
  mathDivide(arg1, arg2) {
    var t1 = arg1.toString();
    var t2 = arg2.toString();
    var r1;
    var r2;
    try {
      t1 = t1.split(".")[1] ? arg1.toString().split(".")[1].length : 0;
      t2 = t2.split(".")[1] ? arg2.toString().split(".")[1].length : 0;
      r1 = Number(arg1.toString().replace(".", ""));
      r2 = Number(arg2.toString().replace(".", ""));
      return (r1 / r2) * Math.pow(10, t2 - t1);
    } catch (error) {
      console.log(error);
    }
  },
  // 将数字处理成为千分位并保留两位小数，同时使用数字补全
  numberProcessing(payload) {
    try {
      // let a = Number(payload)
      const number = util.mathComplement(Number(payload).toFixed(2));
      return number.replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    } catch (error) {
      console.log(error);
    }
  },
  /**
   * Parse the time to string
   * @param {(Object|string|number)} time
   * @param {string} cFormat
   * @returns {string | null}
   */
  parseTime(time, cFormat) {
    if (arguments.length === 0 || !time) {
      return null;
    }
    const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
    let date;
    if (typeof time === "object") {
      date = time;
    } else {
      if (typeof time === "string") {
        if (/^[0-9]+$/.test(time)) {
          // support "1548221490638"
          time = parseInt(time);
        } else {
          // support safari
          // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
          time = time.replace(new RegExp(/-/gm), "/");
        }
      }

      if (typeof time === "number" && time.toString().length === 10) {
        time = time * 1000;
      }
      date = new Date(time);
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay(),
    };
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
      const value = formatObj[key];
      // Note: getDay() returns 0 on Sunday
      if (key === "a") {
        return ["日", "一", "二", "三", "四", "五", "六"][value];
      }
      return value.toString().padStart(2, "0");
    });
    return time_str;
  },
  //时间戳转换 小时+分钟+秒
  formatSeconds(value) {
    let theTime = parseInt(value); // 需要转换的时间秒
    let theTime1 = 0; // 分
    let theTime2 = 0; // 小时
    let theTime3 = 0; // 天
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60);
        theTime1 = parseInt(theTime1 % 60);
        // if (theTime2 > 24) {
        //     //大于24小时
        //     theTime3 = parseInt(theTime2 / 24);
        //     theTime2 = parseInt(theTime2 % 24);
        // }
      }
    }
    let result = "";
    // if (theTime > 0) {
    //     result = "" + parseInt(theTime) + "秒";
    // }
    if (theTime1 > 0) {
      result = "" + parseInt(theTime1) + "分" + result;
    }
    if (theTime2 > 0) {
      result = "" + parseInt(theTime2) + "小时" + result;
    }
    if (theTime3 > 0) {
      result = "" + parseInt(theTime3) + "天" + result;
    }
    return result;
  },
  // 防抖
  Debounce(fn, t) {
    const delay = t || 500;
    let timer;
    return function() {
      const args = arguments;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, args);
      }, delay);
    };
  },
  // 去获取更多券页
  getMoreCoupon() {
    try {
      if (isIOS) {
        window.webkit.messageHandlers.getMoreVoucher.postMessage("1");
      } else {
        EntrepreneurialWorld.getMoreVoucher();
      }
    } catch (error) {
      console.log(error);
      Toast("请升级客户端到最新版本");
    }
  },
  // 版本号对比-目前以1.4.200区分时间bar
  compareVersion(v1, v2) {
    if (sessionStorage.getItem("version")) {
      v1 = sessionStorage.getItem("version");
    } else {
      v1 = v1 || "1.4.200";
    }
    if (!v2) {
      v2 = "1.4.200";
    }
    v1 = v1.split(".");
    v2 = v2.split(".");
    const len = Math.max(v1.length, v2.length);

    while (v1.length < len) {
      v1.push("0");
    }
    while (v2.length < len) {
      v2.push("0");
    }

    for (let i = 0; i < len; i++) {
      const num1 = parseInt(v1[i]);
      const num2 = parseInt(v2[i]);
      if (num1 > num2) {
        return 1;
      } else if (num1 < num2) {
        return -1;
      }
    }
    return 0;
  },
  // app 顶部颜色修改
  // type '1'白色 '0'主题色
  setAppTopColor(type) {
    if (localStorage.getItem("isH5")) {
      return false;
    } else if (util.getUserSystem() === "android") {
      try {
        window.EntrepreneurialWorld.changeStatusColor(type);
      } catch (e) {
        console.log(e);
      }
    } else if (util.getUserSystem() === "ios") {
      try {
        console.log("getUserSystem:---ios ", type);
        window.webkit.messageHandlers.changeStatuColorIos.postMessage(type);
      } catch (e) {
        console.log(e);
      }
    }
  },
  // 千分位展示
  numFormat(num) {
    num = num.toString().split("."); // 分隔小数点
    var arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
    var res = [];
    for (var i = 0, len = arr.length; i < len; i++) {
      if (i % 3 === 0 && i !== 0) {
        res.push(","); // 添加分隔符
      }
      res.push(arr[i]);
    }
    res.reverse(); // 再次倒序成为正确的顺序
    if (num[1]) {
      // 如果有小数的话添加小数部分
      res = res.join("").concat("." + num[1]);
    } else {
      res = res.join("");
    }
    return res;
  },
  // 时间戳转时间格式
  timestampToTime(timestamp) {
    let date = null;
    if (String(timestamp).length === 10) {
      date = new Date(timestamp * 1000);
    } else if (String(timestamp).length === 13) {
      date = new Date(timestamp);
    }
    const Y = date.getFullYear() + "-";
    const M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = date.getDate();
    let h = date.getHours();
    let m = date.getMinutes();
    let s = date.getSeconds();
    D = D < 10 ? "0" + D : D;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    return Y + M + D + " " + h + ":" + m + ":" + s;
  },
  // 调起app相册功能
  toSelectedImage(num) {
    if (util.getUserSystem() === "android") {
      try {
        window.EntrepreneurialWorld.toSelectedImage(num);
      } catch (e) {
        console.log(e);
      }
    } else if (util.getUserSystem() === "ios") {
      try {
        console.log("getUserSystem:---ios ", num);
        window.webkit.messageHandlers.toSelectedImage.postMessage(num);
      } catch (e) {
        console.log(e);
      }
    }
  },
  // 判断是否包含特殊字符串
  RegExpString(str) {
    const p = new RegExp(
      "[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]"
    );
    if (p.test(str)) {
      return true;
    } else {
      return false;
    }
  },
  // 客户端预定下次返回功能 传递参数1则直接关闭 传0则默认
  deallocWebView(str) {
    if (util.getUserSystem() === "android") {
      try {
        console.log("deallocWebView111");
        window.EntrepreneurialWorld.deallocWebView(str);
      } catch (e) {
        console.log(e);
      }
    } else if (util.getUserSystem() === "ios") {
      try {
        console.log("deallocWebView11ios");
        window.webkit.messageHandlers.deallocWebView.postMessage(str);
      } catch (e) {
        console.log(e);
      }
    }
  },
  accSub(arg1, arg2) {
    var r1, r2, m, n;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); // last modify by deeka //动态控制精度长度
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
  },
  
  //传递投流id
  getLiveInvestId(id) {
    if (util.getUserSystem() === "ios") {
        window.webkit.messageHandlers.getLiveInvestId.postMessage(id);
    } else if (util.getUserSystem() === "android") {
      try {
        window.EntrepreneurialWorld.getLiveInvestId(id)
      } catch (error) {
        console.log(error);
      }
    }
  },
  //关闭webview
  leaveToLive() {
    if (util.getUserSystem() === "ios") {
        window.webkit.messageHandlers.closeProviousPage.postMessage("");
    } else if (util.getUserSystem() === "android") {
      try {
        window.EntrepreneurialWorld.closeProviousPage()
      } catch (error) {
        console.log(error);
      }
    }
  },
  // 客户端返回功能 传递参数1则直接关闭 传0则默认
  closeOrBackPage(str) {
    if (util.getUserSystem() === "android") {
      try {
        window.EntrepreneurialWorld.closeOrBackPage(str);
      } catch (e) {
        console.log(e);
      }
    } else if (util.getUserSystem() === "ios") {
      try {
        window.webkit.messageHandlers.closeOrBackPage.postMessage(str);
      } catch (e) {
        console.log(e);
      }
    }
  },
  // 调起app相机功能
  toOpenCamera() {
    if (util.getUserSystem() === "android") {
      try {
        window.EntrepreneurialWorld.toOpenCamera();
      } catch (e) {
        console.log(e);
      }
    } else if (util.getUserSystem() === "ios") {
      try {
        window.webkit.messageHandlers.toOpenCamera.postMessage();
      } catch (e) {
        console.log(e);
      }
    }
  },
  // 调起app文件夹选择pdf功能
  toSelectedFile(type) {
    if (util.getUserSystem() === "android") {
      try {
        type = JSON.stringify(type);
        window.EntrepreneurialWorld.toSelectedFile(type);
      } catch (e) {
        console.log(e);
      }
    } else if (util.getUserSystem() === "ios") {
      try {
        console.log("getUserSystem:---ios ", type);
        window.webkit.messageHandlers.toSelectedFile.postMessage(type);
      } catch (e) {
        console.log(e);
      }
    }
  },
  // 格式化昵称 *号
  formatName(val) {
    let newStr;
    if (val.length === 2) {
      newStr = val.substr(0, 1) + "*";
    } else if (val.length > 2) {
      var char = "";
      for (var i = 0, len = val.length - 2; i < len; i++) {
        char += "*";
      }
      newStr = val.substr(0, 1) + char + val.substr(-1, 1);
    } else {
      newStr = val;
    }
    return newStr;
  },
  // 跳实名认证
  needCertification(data) {
    console.log("跳转实名认证");
    if (isIOS) {
      try {
        window.webkit.messageHandlers.needCertification.postMessage(data);
      } catch (e) {
        console.log("暂无实名新方法");
      }
    } else if (isAndroid) {
      try {
        EntrepreneurialWorld.needCertification(JSON.stringify(data));
      } catch (e) {
        console.log("暂无实名新方法");
      }
    }
  },
};
export default util;
