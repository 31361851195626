/*
 * @Author: CtrlC
 * @Date: 2023-06-20 23:04:08
 * @LastEditors: CtrlC
 * @LastEditTime: 2023-06-25 10:34:28
 * @Description: file content
 * @FilePath: /dczd-h5/src/common/checkUpdate.js
 */
import axios from 'axios'

 class CheckUpdate {
	getLocalVersion() {	
		return Number(document.getElementsByTagName("meta").buildVersion.content);
	}
	async getServerVersion() {	
		return fetch('/version.json?v=' + Date.now(), {}, {isRes: true}).then(async rsp => {
			const {version} = await rsp.json()
			console.log('vvvv', version)
            return Number(version)
		}).catch(err => {
			console.error('errr', err)
		})
	}
	async compareVersion() {
		const sv = await this.getServerVersion();
		const cv = 	this.getLocalVersion()
		return cv === sv
	}
}

export default new CheckUpdate()