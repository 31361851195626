/*
 * @Author: xiaotianli 1076817427@qq.com
 * @Date: 2022-09-06 16:42:03
 * @LastEditors: xiaotianli 1076817427@qq.com
 * @LastEditTime: 2023-01-15 10:51:52
 * @FilePath: /H5-activity/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Router from 'vue-router'
import utils from '@/common/utils.js'
import vendor from './vendor'
import sign from './sign'
import bean from './bean'
import integral from './integral'
import article from './article'
import overseas from './overseas';
import rule from './rule'
import shareIntegral from './shareIntegral'
import prize from './prize.js'
import cancellation from './cancellation.js'
import share from './share.js'
import rewards from './proRewards.js'
import integralCenter from './integralCenter.js'
import newSettlement from './newSettlement.js'
import checkUpdate from '@/common/checkUpdate'


if (!Window.VueRouter) Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'Index',
			component: () => import('@/views/cytx_sign/sign'),
			meta: {
				title: '签到'
			}
		},
		{
			path: '/flowSetting',
			name: 'flowSetting',
			component: () => import('@/views/flowSetting/index'),
			meta: {
				title: '短视频投流设置',
				keepAlive: true
			}
		},
		{
			path: '/liveFlowSetting',
			name: 'liveFlowSetting',
			component: () => import('@/views/flowSetting/liveFlowSetting'),
			meta: {
				title: '直播投流设置',
				keepAlive: true
			}
		},
		{
			path: '/mapSet',
			name: 'mapSet',
			component: () => import('@/views/flowSetting/mapSet/index'),
			meta: {
				title: '按附近区域选择'
			}
		},
		{
			path: '/transferApk',
			name: 'transferApk',
			component: () => import('@/views/transferApk'),
			meta: {
				title: 'app'
			}
		},
		{
			path: '/webview',
			name: 'webview',
			component: () => import('@/views/webview'),
			meta: {
				title: 'app'
			}
		},
		...sign,
		...vendor,
		...bean,
		...integral,
		...article,
		...overseas,
		...rule,
		...shareIntegral,
		...prize,
		...cancellation,
		...share,
		...rewards,
		...integralCenter,
		...newSettlement
	]
})

router.beforeEach(async(to, from, next) => {
	try {
		const res = await checkUpdate.compareVersion()
		console.info('checkUpdate',res)
		let num = Number(sessionStorage.getItem('isReload'))
		if(Number(sessionStorage.getItem('isReload')) < 2){
			if(!res){
				window.location.reload();
				++num
				sessionStorage.setItem('isReload',num)
			}
		}
	} catch (error) {
		console.info(error)
	}
	const title = to.meta.title
	if(to.name === 'integral' || to.name === 'bean'){
		if(to.query.token){
			sessionStorage.setItem('access_token', to.query.token)
		}
	}
	// console.log(to, 'router-to----------');
	// console.log(to.meta.title);
	utils.setNavigatorTitle(title)
	next();
})

export default router