<template>
	<div id="app">
		<div class="loading" v-if="isShowLoading">
			<van-loading />
		</div>

		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" />
	</div>
</template>

<script>
	import utils from "@/common/utils.js";
	export default {
		name: "App",
		data() {
			return {
				isShowLoading: true,
			};
		},
		created() {
			setTimeout(() => {
				this.isShowLoading = false;
			}, 500);
			try {
				// let appData = {
				// 	telephone: '13096977829'
				// }
				let appData = utils.getAppData(); //获取创业天下app传递信息
				// sessionStorage.setItem( "access_token","2ee58a04b524894c743c8cf1807de31e");
				if (appData.access_token) {
					sessionStorage.setItem("access_token", appData.access_token);
					if (appData.type) {
						sessionStorage.setItem("type", appData.type);
					}
					let phoneNumber =
						appData.telephone.substr(0, 3) + "****" + appData.telephone.substr(7);
					sessionStorage.setItem("phoneNumber", phoneNumber);
				}
				console.log('从app获取到参数type:', appData);
				// console.log(appData)
			} catch (err) {
				console.log(err);
				const search = window.location.search
				if (search.indexOf('admin') != -1) {
					let access_token = search.split('access_token=')[1].split('&')[0]
					let phoneNumber = search.split('phoneNumber=')[1].split('&')[0]
					let admin = search.split('admin=')[1].split('&')[0]
					if (admin && access_token && phoneNumber) {
						sessionStorage.setItem("access_token", access_token);
						sessionStorage.setItem("phoneNumber", phoneNumber);
						console.log('admin open', access_token, phoneNumber)
					}
				}
			}
		},
	};
</script>

<style>
	body,
	html {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		/* background-color: #F4F6F8; */
		/*背景色修改 */
	}

	#app {
		width: 100%;
		height: 100%;
		min-height: 100%;
		padding: 0;
		margin: 0;
		/* background-color: #1BC392; */
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
	}

	.warning-tips {
		padding-top: 50%;
		text-align: center;
	}

	.loading {
		width: 100px;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		left: calc(50% - 50px);
		top: calc(50% - 50px);
		z-index: 999999999;
	}
</style>
