const prize = [

    {
        path: '/giftCenter',
        name: 'giftCenter',
        component: () => import('@/views/cytx_prize/giftCenter'),
        meta: {
            title: '奖品中心',
            keepAlive: false
        }
    },

    {
        path: '/prize_record',
        name: 'prize_record',
        component: () => import('@/views/cytx_prize/prize_record'),
        meta: {
            title: '获奖记录'
        }
    },
    {
        path: '/exchange_list',
        name: 'exchange_list',
        component: () => import('@/views/cytx_prize/exchange_list'),
        meta: {
            title: '兑换福利',
            keepAlive: false
        }
    },
    {
        path: '/exchange_detail',
        name: 'exchange_detail',
        component: () => import('@/views/cytx_prize/exchange_detail'),
        meta: {
            title: '福利详情',
            keepAlive: false
        }
    },
    {
        path: '/exchange_success',
        name: 'exchange_success',
        component: () => import('@/views/cytx_prize/exchange_success'),
        meta: {
            title: '兑换成功',
            keepAlive: false
        }
    }

]

export default prize