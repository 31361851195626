const article = [
    {
        path: '/article',
        name: 'article',
        component: () => import('@/views/CytxArticle/article'),
        meta: {
            title: '资讯页',
            keepAlive: true
        }
    }
   
]

export default article