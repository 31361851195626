/*
 * @Author: xiaotianli 1076817427@qq.com
 * @Date: 2023-01-15 10:47:42
 * @LastEditors: CtrlC
 * @LastEditTime: 2023-01-16 21:29:04
 * @FilePath: /H5-activity/src/router/newSettlement.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const newSettlement = [{
		path: '/signingAttorney',
		name: 'signingAttorney',
		component: () => import( /* webpackChunkName: "addressList" */ '@/views/newSettlement/signAttorney'),
		meta: {
			title: '委托书签署'
		}
	},
	{
			path: '/signingPromotional',
			name: 'signingPromotional',
			component: () => import( /* webpackChunkName: "addressList" */ '@/views/newSettlement/signingPromotional'),
			meta: {
				title: '推广服务协议签署'
			}
		},
	
	{
		path: '/uploadInvoice',
		name: 'uploadInvoice',
		component: () => import( /* webpackChunkName: "addressList" */ '@/views/newSettlement/uploadInvoice'),
		meta: {
			title: '上传发票'
		}
	},
	{
		path: '/settlementDetail',
		name: 'settlementDetail',
		component: () => import( /* webpackChunkName: "addressList" */ '@/views/newSettlement/settlementDetail'),
		meta: {
			title: '结算详情'
		}
	},
	{
		path: '/individualIndex',
		name: 'individualIndex',
		component: () => import( /* webpackChunkName: "addressList" */ '@/views/newSettlement/individual/index'),
		meta: {
			title: '个体户结算'
		}
	},
	{
		path: '/individualDetail',
		name: 'individualDetail',
		component: () => import( /* webpackChunkName: "addressList" */
			'@/views/newSettlement/individual/individualDetail'),
		meta: {
			title: '结算详情'
		}
	},
	{
		path: '/realNameResult',
		name: 'realNameResult',
		component: () => import('@/views/newSettlement/realNameResult'),
		meta: {
			index: 4,
			title: '实名认证结果',
			loginCheck: true
		}
	},
	{
		path: '/settleIn',
		name: 'settleIn',
		component: () => import('@/views/newSettlement/settleIn'),
		meta: {
			index: 4,
			title: '入驻',
			loginCheck: true,
			keepAlive: true
		}
	},
	{
		path: '/settleInDetail',
		name: 'settleInDetail',
		component: () => import('@/views/newSettlement/settleInDetail'),
		meta: {
			index: 4,
			title: '入驻详情',
			loginCheck: true
		}
	},
	{
		path: '/realnameAuthResult',
		name: 'realnameAuthResult',
		component: () => import('@/views/realnameAuthResult/index'),
		meta: {
			title: '实名认证',
		}
	},
	{
		path: '/chooseBank',
		name: 'chooseBank',
		component: () => import('@/views/newSettlement/chooseBank'),
		meta: {
			title: '选择银行卡',
		}
	},
	{
		path: '/enterpriseData',
		name: 'enterpriseData',
		component: () => import('@/views/newSettlement/enterpriseData'),
		meta: {
			title: '资料',
		}
	}
]
export default newSettlement