/*
 * @Author: CtrlC
 * @Date: 2022-07-27 15:57:28
 * @LastEditors: zhanjiayue 834722022@qq.com
 * @LastEditTime: 2022-08-18 16:41:18
 * @Description: 推广奖励
 * @FilePath: /H5-activity/src/router/proRewards.js
 */
const rewards = [
    {
		path: '/cytx_proRewards',
		name: 'proRewards',
		component: () => import('@/views/cytx_proRewards/index'),
		meta: {
			title: '推广奖励'
		}
	},
    {
		path: '/cytx_appreciateInfo',
		name: 'appreciateInfo',
		component: () => import('@/views/cytx_appreciateInfo/index'),
		meta: {
			title: '赞赏明细'
		}
	},
	{
		path: '/invitationDetails',
		name: 'invitationDetails',
		component: () => import('@/views/cytx_proRewards/invitationDetails'),
		meta: {
			title: '邀请明细'
		}
	},
	{
		path: '/cytx_fansInfo',
		name: 'fansInfo',
		component: () => import('@/views/cytx_fans/index'),
		meta: {
			title: '粉丝贡献榜'
		}
	},
	{
		path: '/fansInfo_search',
		name: 'fansInfoSearch',
		component: () => import('@/views/fansInfo_search/index'),
		meta: {
			title: '搜索'
		}
	}
]

export default rewards