const overseas = [
    {
		path: '/download',
		name: 'download',
		component: () => import('@/views/CytxOverseas/download'),
		meta: {
			title: '下载'
		}
	}
]

export default overseas