const sign = [
	{
		path: '/sign',
		name: 'sign',
		component: () => import('@/views/cytx_sign/sign'),
		meta: {
			title: '签到'
		}
	},
    {
		path: '/rule',
		name: 'rule',
		component: () => import('@/views/cytx_sign/rule'),
		meta: {
			title: '签到规则'
		}
	},{
		path: '/wisdomMall',
		name: 'wisdomMall',
		component: () => import('@/views/tips.vue'),
		meta: {
			title: '敬请期待'
		}
	}
	,{
		path: '/giftList',
		name: 'giftList',
		component: () => import('@/views/cytx_sign/giftList.vue'),
		meta: {
			title: '创业券记录'
		}
	}
]

export default sign