const integral = [
    {
        path: '/integral',
        name: 'integral',
        component: () => import('@/views/CytxIntegral/integral/index'),
        meta: {
            title: '积分明细',
            keepAlive: true
        },
    },
    {
        path: '/integralDetail',
        name: 'integralDetail',
        component: () => import('@/views/CytxIntegral/detail/index'),
        meta: {
            title: '积分详情'
        }
    }
   
]

export default integral