/*
 * @Author: xiaotianli 1076817427@qq.com
 * @Date: 2022-11-04 17:37:25
 * @LastEditors: xiaotianli 1076817427@qq.com
 * @LastEditTime: 2022-11-04 17:38:03
 * @FilePath: /H5-activity/src/common/filters.js
 * @Description: 过滤器
 */
export function cancelDecimal(data) {
	return String(data).split('.')[0]
}