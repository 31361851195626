/*
 * @Author: xiaotianli 1076817427@qq.com
 * @Date: 2023-02-01 15:21:05
 * @LastEditors: 李梦 719771893@qq.com
 * @LastEditTime: 2023-05-15 15:20:16
 * @FilePath: /H5-activity-event/src/router/bean.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const bean = [
    {
        path: '/bean',
        name: 'bean',
        component: () => import('@/views/CytxBean/bean/index'),
        meta: {
            // title: '创业豆明细', // 消费券/创业豆记录
            title: '',
            keepAlive: true
        }
    },
    {
        path: '/beanDetail',
        name: 'beanDetail',
        component: () => import('@/views/CytxBean/detail/index'),
        meta: {
            title: '交易详情'
        }
    }
]

export default bean