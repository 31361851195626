const shareIntegral = [{
        path: '/integralCenter',
        name: 'integralCenter',
        component: () => import('@/views/cytx_shareIntegral/integralCenter/index'),
        meta: {
            title: '积分中心'
        }
    },
    {
        path: '/shareIntegral',
        name: 'shareIntegral',
        component: () => import('@/views/cytx_shareIntegral/share/index'),
        meta: {
            title: '共享积分'
        }
    },
    {
        path: '/shareDetail',
        name: 'shareDetail',
        component: () => import('@/views/cytx_shareIntegral/shareDetail/index'),
        meta: {
            title: '共享积分详情'
        }
    }
]

export default shareIntegral