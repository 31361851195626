/*
 * @Author: xiaotianli 1076817427@qq.com
 * @Date: 2022-09-06 16:42:03
 * @LastEditors: CtrlC
 * @LastEditTime: 2023-01-16 15:14:28
 * @FilePath: /H5-activity/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import '@/common/flexible.js'
// import '@/common/vant.js'
import 'vant/lib/index.css'
import './scss/index.scss'
import vant from 'vant'
Vue.use(vant)
import Vconsole from 'vconsole'
import * as fundebug from "fundebug-javascript";
import fundebugVue from "fundebug-vue";
import * as filters from './common/filters.js'
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp);
Vue.config.productionTip = false

Vue.prototype.$Router = router

Vue.filter('getImg', function(img) {
	if (!img) return ''
	if (/^(http|https):\/\//.test(img)) {
		return img
	} else {
		return process.env.VUE_APP_STATIC_URL + img
	}
})
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])// 插入过滤器名和对应方法
})

if (process.env.NODE_ENV === "production") {
	console.log('prod')
	fundebug.init({
		apikey: 'd208d179a609c406f24ab1a8677770f427e8f0bd0b576ac1121fd34a454ecf9b',
		silentHttp: true,
		silentResource: true,
		filters: [{
				message: /^unhandledrejection$/
			},
			{
				message: /unhandledrejection/
			},
			{
				message: /^Script error\.$/
			}
		]
	});
	fundebugVue(fundebug, Vue);
} else {
	new Vconsole();
}
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
