/*
 * @Author: CtrlC
 * @Date: 2022-04-15 14:30:45
 * @LastEditors: CtrlC
 * @LastEditTime: 2022-10-25 14:44:07
 * @Description: file content
 * @FilePath: /H5-activity/src/router/share.js
 */
const video_share = [
    {
        path: '/video_share',
        name: 'video_share',
        component: () => import('@/views/cytx_share/video'),
        meta: {
            title: '视频分享',
            // keepAlive: true
        }
    },
    {
        path: '/share_pay',
        name: 'share_pay',
        component: () => import('@/views/cytx_share/pay'),
        meta: {
            title: '视频分享',
            // keepAlive: true
        }
    }
   
]

export default video_share