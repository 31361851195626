const cancellation = [{
	path: '/instructions',
	name: 'instructions',
	component: () => import('@/views/cytx_cancellation/instructions'),
	meta: {
		title: '账号注销说明',
		keepAlive: false
	}
},
{
	path: '/cancellation',
	name: 'cancellation',
	component: () => import('@/views/cytx_cancellation/cancellation'),
	meta: {
		title: '账号注销',
		keepAlive: false
	}
},
{
	path: '/accountVerification',
	name: 'accountVerification',
	component: () => import('@/views/cytx_cancellation/accountVerification'),
	meta: {
		title: '账号注销',
		keepAlive: false
	}
},
{
	path: '/noCancellation',
	name: 'noCancellation',
	component: () => import('@/views/cytx_cancellation/noCancellation'),
	meta: {
		title: '账号注销',
		keepAlive: false
	}
},
{
	path: '/status',
	name: 'status',
	component: () => import('@/views/cytx_cancellation/status'),
	meta: {
		title: '账号注销',
		keepAlive: false
	}
},
{
	path: '/cancellationAgreement',
	name: 'cancellationAgreement',
	component: () => import('@/views/cytx_cancellation/cancellationAgreement'),
	meta: {
		title: '创业天下账号注销协议',
		keepAlive: false
	}
}

]

export default cancellation
