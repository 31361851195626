const vendor = [
    {
		path: '/vendor',
		name: 'vendor',
		component: () => import('@/views/CytxVendor/cytx_vendor/index'),
		meta: {
			title: '供应商',
			keepAlive: true
		}
	},{
		path: '/liveRecord',
		name: 'liveRecord',
		component: () => import('@/views/CytxVendor/cytx_liveRecord/index'),
		meta: {
			title: '直播记录'
		}
	},{
		path: '/liveLearnDetail',
		name: 'liveLearnDetail',
		component: () => import('@/views/CytxVendor/cytx_liveLearnDetail/index'),
		meta: {
			title: '详情'
		}
	},{
		path: '/liveInfo',
		name: 'liveInfo',
		component: () => import('@/views/CytxVendor/cytx_live_info/index'),
		meta: {
			title: '直播详情'
		}
	},{
		path: '/playLive',
		name: 'playLive',
		component: () => import('@/views/CytxVendor/cytx_play_live/index'),
		meta: {
			title: '供应商'
		}
	},{
		path: '/playLing',
		name: 'playLing',
		component: () => import('@/views/CytxVendor/play_ling/index'),
		meta: {
			title: '供应商'
		}
	},
	{
		path: '/agreePage',
		name: 'agreePage',
		component: () => import('@/views/CytxVendor/agree_page/index'),
		meta: {
			title: '天下直播用户协议'
		}
	}
]

export default vendor