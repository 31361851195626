const sign = [
    {
		path: '/cytx_rule',
		name: 'rule',
		component: () => import('@/views/cytx_rule/rule'),
		meta: {
			title: '用户隐私协议'
		}
	},
	{
		path: '/cytx_rule_privacy',
		name: 'rule',
		component: () => import('@/views/cytx_rule/privacy'),
		meta: {
			title: '用户隐私政策'
		}
	}
]

export default sign